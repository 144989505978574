import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import useOutsideClick from 'hooks/useOutsideClick';
import { Button, DropdownNew } from 'vgui';

import './style.css';

const DropdownWidget = (props) => {
	const {
		children = null,
		className = '',
		options = [],
		iconUp = <ChevronUpIcon className="w-5 h-5" />,
		iconDown = <ChevronDownIcon className="w-5 h-5" />,
		autoclose = 10000,
	} = props;

	const { ref, isComponentVisible, setIsComponentVisible } = useOutsideClick(false);

	let timer = null;
	const toggleDropDown = () => {
		clearTimeout(timer);
		if (!isComponentVisible) {
			timer = setTimeout(() => {
				setIsComponentVisible(false);
				clearTimeout(timer);
			}, autoclose);
		}

		setIsComponentVisible((prevState) => !prevState);
	};

	if (!options.length || options.every((v) => !v)) return;

	return (
		<div>
			<Button
				ref={ref}
				round
				size="rect"
				variant="transparent"
				className={classNames({ 'text-gray-600 hover:text-gray-900 bg-gray-200': isComponentVisible }, className)}
				onClick={toggleDropDown}
			>
				{children}
				{isComponentVisible && iconUp}
				{!isComponentVisible && iconDown}
			</Button>
			<DropdownNew {...{ isComponentVisible, setIsComponentVisible, ref }} className="right-0" options={options} />
		</div>
	);
};

export default DropdownWidget;
