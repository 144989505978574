import classNames from 'classnames';
import { ForwardRefRenderFunction, MouseEvent, PropsWithChildren, forwardRef } from 'react';
import React from 'react';
import { Spinner } from 'vgui';

import './style.css';

interface IButton {
	type?: 'button' | 'submit' | 'reset';
	size?: 'default' | 'small' | 'big' | 'rect';
	variant?: 'success' | 'secondary' | 'transparent' | 'danger' | 'danger-transparent' | 'success-fill';
	round?: boolean;
	disabled?: boolean;
	loading?: boolean;
	className?: string;
	stopOther?: boolean;
	onClick?: (e: MouseEvent) => void;
	[key: string]: any;
	id?: string;
}

const Button: ForwardRefRenderFunction<HTMLButtonElement, PropsWithChildren<IButton>> = (props, ref) => {
	const {
		children,
		size = 'default',
		type = 'button',
		variant = 'success',
		round = false,
		className,
		onClick,
		disabled = false,
		loading = false,
		stopOther = true,
		id,
		...other
	} = props;

	const isDisabled = disabled || loading;

	return (
		<button
			id={id}
			ref={ref}
			type={type}
			className={classNames(
				className,
				{ round },
				size,
				loading && 'flex items-center',
				isDisabled ? 'disabled' : variant,
			)}
			onClick={(e) => {
				if (stopOther) e.stopPropagation();

				if (!isDisabled && onClick) onClick(e);
			}}
			disabled={isDisabled}
			{...other}
		>
			{loading && <Spinner size="xs" className="mr-1" />}
			{children}
		</button>
	);
};

export default forwardRef(Button);
