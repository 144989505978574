import { datePretty, lastSeen, datetimeShort, timeShort, dateSeparator } from './date';
import plural from './plural';
import toQueryString from './toQueryString';
import { setTitle, relative } from './utils';
import { parseEmoji } from './emoji';
import htmlToText from './htmlToText';

const rxUrl =
	/(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)[a-zа-я0-9]+([-.]{1,2}[a-zа-я0-9]+)*\.[a-zрф]{2,10}(:[0-9]{1,5})?([^<\s]+)?/gm;

function numberFormat(n) {
	return n.toFixed().replace(/\d(?=(\d{3})+$)/g, '$& ');
}

function isURL(url) {
	if (!url) return url;
	return new RegExp('^' + rxUrl.source, rxUrl.flags).test(url.trim());
}

function matchURL(text) {
	return text.match(rxUrl);
}

export {
	dateSeparator,
	datePretty,
	toQueryString,
	plural,
	numberFormat,
	isURL,
	matchURL,
	lastSeen,
	setTitle,
	relative,
	datetimeShort,
	timeShort,
	parseEmoji,
	htmlToText,
};
