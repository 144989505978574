import { Fragment, Suspense, lazy, memo, useEffect, useState } from 'react';
import { GROUPS_WITH_ADV, TITLE_SUFFIX } from 'constants/Config';
import { setTitle } from 'helpers/utils';
import { useToaster } from 'hooks';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Adv } from 'modules/Adv';
import { Reach } from 'modules/Analytics';
import { useAuth, useAuthModal } from 'modules/Auth/hooks';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { Error, ErrorScreen } from 'modules/Error';
import { Button, Spinner, Stack } from 'vgui';
import { groupStoreNew, postStoreNew } from 'stores';

import CommentListContainer from 'modules/Comment/containers/CommentListContainer';
import PostItem from 'modules/Post/components/PostItem';
import PostView from 'modules/Post/components/PostView';

const PostListRelatedContainer = lazy(() => import('./PostListRelatedContainer'));

const PostViewContainer = ({ id, modal = false, onLoad = () => {}, onClose }) => {
	const showAuthModal = useAuthModal('post');
	const { toastPromise, toastError } = useToaster();
	const [error, setError] = useState();

	const [searchParams] = useSearchParams();
	const editMode = searchParams.get('mode');

	const item = postStoreNew.entity(id);

	useEffect(() => {
		if (!postStoreNew.entity(id)) {
			postStoreNew
				.get(id)
				.then((data) => onLoad(data))
				.catch((e) => {
					setError(e);
				});
		} else {
			onLoad(postStoreNew.entity(id));
		}
		// eslint-disable-next-line
	}, [id]);

	const { isAuth, user } = useAuth();

	useEffect(() => {
		if (item?.id && item?.permissions?.can_post_view) Reach('post_view', { id: item?.id, ck: item?.ck });
		if (item) setTitle(item?.seo?.title || item?.header || 'Пост #' + item?.id + TITLE_SUFFIX);
	}, [item]);

	const handlePressSubscribe = (action, groupid) => {
		if (!isAuth) return showAuthModal();
		groupStoreNew.patch(id, { action }).catch(() => toastError());

		postStoreNew.entities().forEach((post) => {
			let el = toJS(post);
			el.decoration.header.access = action === 'subscribe' ? 'new' : null;
			postStoreNew.update(el?.id, el);
		});
	};

	const handleDeleteItem = (id) => {
		toastPromise(postStoreNew.patch(id, { action: 'delete' }), {
			loading: 'Удаление...',
			success: () => {
				postStoreNew.update(id, { deleted: true });
				return 'Пост удалён';
			},
			error: 'Ошибка удаления',
		});
	};

	const handleRestoreItem = (id) => {
		toastPromise(postStoreNew.patch(id, { action: 'restore' }), {
			loading: 'Восстановление...',
			success: () => {
				postStoreNew.update(id, { deleted: false });
				return 'Пост восстановлен';
			},
			error: 'Ошибка восстановления',
		});
	};

	const handleHideItem = (id) => postStoreNew.update(id, { hide: true });

	const handleEvent = (item, action, params = {}) => {
		if (item?.permissions?.can_post_view) Reach(action, { id: item?.id, ck: item?.ck, ...params });
	};

	const handleBookmark = (action) => {
		toastPromise(postStoreNew.patch(item?.id, { action: action ? 'mark' : 'unmark' }), {
			loading: 'Отправка...',
			success: () => (action ? 'Пост добавлен в закладки' : 'Пост удалён из закладок'),
			error: 'Ошибка отправки',
		});
	};

	if (error) return <Error e={error} />;
	if (!item) return <Spinner fullscreen />;

	if (item?.permissions?.can_post_view === false) {
		return <ErrorScreen code={403} text="Доступ к контенту закрытой группы ограничен" />;
	}

	const hasAdv = item?.group && GROUPS_WITH_ADV.includes(item?.group?.id) && !item?.params?.adver;

	return (
		<Fragment>
			{!modal && hasAdv ? <Adv id="adfox_151731223791016696" className="py-2" /> : null}

			<PostView
				{...item}
				modal={modal}
				isAuth={isAuth}
				onClose={onClose}
				editMode={!!editMode}
				onHide={handleHideItem}
				onDelete={handleDeleteItem}
				onBookmark={handleBookmark}
				onRestore={handleRestoreItem}
				onPressSubscribe={handlePressSubscribe}
				footer={<Footer {...{ item, userId: user?.id }} />}
				onEvent={(action, params) => handleEvent(item, action, params)}
			/>

			{!modal && hasAdv ? (
				<Suspense>
					<Stack>
						<Adv id="adfox_165267629953331552" className="pt-4" />
						<PostListRelatedContainer
							id={id}
							alias={item?.decoration?.header?.url}
							renderItem={(item) => (
								<PostItem
									{...item}
									isAuth={isAuth}
									onHide={handleHideItem}
									onDelete={handleDeleteItem}
									onRestore={handleRestoreItem}
									onPressSubscribe={handlePressSubscribe}
									onShow={(id, ck) => Reach('post_reach', { id, ck })}
									onOpen={(id, ck) => Reach('post_open', { id, ck })}
									onEvent={(action, params) => handleEvent(item, action, params)}
								/>
							)}
						/>
					</Stack>
				</Suspense>
			) : null}
		</Fragment>
	);
};

export default observer(PostViewContainer);

const NoAuthBlock = () => {
	const location = useLocation();

	return (
		<Stack id="comments" className="items-center border-t border-dotted pt-4 pb-2">
			<p className="text-sm text-center">
				Используйте мобильное приложение, чтобы&nbsp;читать&nbsp;и&nbsp;добавлять&nbsp;комментарии
			</p>
			<Link to="/install" state={{ from: location?.pathname }}>
				<Button stopOther={false}>Открыть в приложении</Button>
			</Link>
		</Stack>
	);
};

const Footer = memo(
	({ item, userId }) => {
		return !userId ? (
			<NoAuthBlock />
		) : item?.params['no_comments'] ? (
			<span className="text-sm">Автор ограничил возможность комментировать пост</span>
		) : !item?.permissions?.can_post_comment ? (
			<span></span>
		) : (
			<CommentListContainer id={item?.id} filter={{ postId: item?.id, key: 'post_comment_' + item?.id }} />
		);
	},
	(a, b) => a.userId === b.userId && a.item.id === b.item.id,
);
