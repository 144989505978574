import { ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import useOutsideClick from 'hooks/useOutsideClick';
import React, { useEffect, useRef, useState } from 'react';

import { Button } from '../button';
import { DropdownNew } from '../dropdown';
import InputNew, { IInput } from '../input/Input.tsx';

interface ISearch extends IInput {
	classOptions?: string;
	defaultValue?: string;
	name?: string;
	list: string[];
	placeholder?: string;
	maxHeight?: number;
	options?: Array<any>;
	loading?: boolean;
	value: string;
	render: (...any) => JSX.Element;
}

type SearchComponent = React.ForwardRefExoticComponent<ISearch & React.RefAttributes<HTMLInputElement>> & {
	Option?: (props: any) => JSX.Element;
};

const SearchButton = ({ inputRef, isComponentVisible }) => (
	<Button
		variant="success-fill"
		size="small"
		onMouseDown={(e) => {
			e.preventDefault();

			inputRef.current[isComponentVisible ? 'blur' : 'focus']();
		}}
	>
		<ChevronDownIcon className={classNames('w-4 h-4 transition-transform', { 'rotate-180': isComponentVisible })} />
	</Button>
);

const Search: SearchComponent = React.forwardRef<HTMLInputElement, ISearch>((props, searchRef) => {
	const {
		className,
		onChange,
		onInput,
		onOpen,
		onClose,
		options,
		loading = false,
		render,
		size = 'default',
		placeholder,
		value: inputValue = '',
		id: inputId = '',
		maxHeight,
	} = props;
	const { ref, isComponentVisible, setIsComponentVisible } = useOutsideClick(false, searchRef);
	const inputRef = useRef();
	const [value, setValue] = useState(inputValue);
	const [id, setId] = useState(inputId);

	const handleChange = ({ id, value }) => {
		setValue(value);
		setId(id);
		if (onChange) onChange({ id, value });
	};

	useEffect(() => {
		setValue(inputValue);
	}, [inputValue]);

	const handleFocus = () => {
		setIsComponentVisible(true);
		if (onOpen) onOpen();
	};

	const handleBlur = () => {
		setIsComponentVisible(false);
		if (onClose) onClose();
	};

	return (
		<div className={classNames('searchbox', className)} ref={ref}>
			<InputNew
				onChange={(e) => {
					setValue(e.target.value);
					if (onInput) onInput(e.target.value);
				}}
				size={size}
				value={value}
				ref={inputRef}
				placeholder={placeholder}
				title={placeholder}
				onFocus={handleFocus}
				onBlur={handleBlur}
			>
				<SearchButton {...{ inputRef, isComponentVisible }} />
			</InputNew>
			<DropdownNew
				{...{ maxHeight, isComponentVisible, setIsComponentVisible, id }}
				optionPlaceholder={!value && 'Введите запрос'}
				loading={loading}
				ref={inputRef}
				onChange={handleChange}
				options={options}
				render={({ item, selected, onClick, elementId }) => {
					const onNewClick = () => {
						onClick();
						inputRef.current.blur();
					};

					return render ? (
						render({ selected, item, onClick: onNewClick, key: item.id })
					) : (
						<DropdownNew.Option onClick={onNewClick} selected={selected} key={item.id} id={elementId}>
							{item.value}
						</DropdownNew.Option>
					);
				}}
			></DropdownNew>
		</div>
	);
});

export default Search;
