import { HandThumbUpIcon, HandThumbDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import {
	HandThumbUpIcon as HandThumbUpIconSolid,
	HandThumbDownIcon as HandThumbDownIconSolid,
} from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { emojify } from 'helpers/emoji';
import substrHtml from 'helpers/substrHTML';
import { relative } from 'helpers/utils';
import { useIntersection } from 'hooks/intersectionObserver';
import { Reach } from 'modules/Analytics';
import { AttachmentPhoto, AttachmentVideo } from 'modules/Attachment';
import useAuthModal from 'modules/Auth/hooks/useAuthModal';
import CommentEditContainer from 'modules/Comment/containers/CommentEditContainer';
import { useComplaintModal, ComplaintTypes } from 'modules/Complaint';
import { sendMessagePrivate } from 'modules/Messenger/actions';
import { isDeleted } from 'modules/Profile/helpers/ProfileHelpers';
import { memo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, HStack, Stack, DatePretty, Card, Pressable, HtmlBlock, Link, DropdownWidget, PhoneIcon } from 'vgui';
import { useWindowDimensions } from 'hooks';

import ProfileHeader from '../../Profile/components/ProfileHeader';

const CommentVote = memo(
	({ id, rating, onPressVote, voted, disabled }) => {
		const iconYes = voted === 'y' ? <HandThumbUpIconSolid /> : <HandThumbUpIcon />;
		const iconNo = voted === 'n' ? <HandThumbDownIconSolid /> : <HandThumbDownIcon />;

		return (
			<HStack className="gap-2">
				<Button
					disabled={disabled}
					round
					size="small"
					variant="secondary"
					onClick={disabled ? undefined : () => onPressVote(id, 'y')}
				>
					<span className="w-4 h-4">{iconYes}</span>
					{(rating && rating.yes) || 0}
				</Button>
				<Button
					disabled={disabled}
					round
					size="small"
					variant="secondary"
					onClick={disabled ? undefined : () => onPressVote(id, 'n')}
				>
					<span className="w-4 h-4">{iconNo}</span>
					{(rating && rating.no) || 0}
				</Button>
			</HStack>
		);
	},
	(a, b) => a.rating === b.rating && a.disabled === b.disabled,
);

const CommentParent = ({ id, status, content, author, onClickParent }) => {
	const cutContent = content.length > 150 ? substrHtml(content, 150).trim() + '...' : content;
	return (
		<Stack
			className="flex text-xs border border-l-4 p-2 my-2 rounded overflow-hidden cursor-pointer"
			onClick={() => onClickParent(id)}
		>
			<span className="text-left text-primary mb-1">{(author ? author.nick : 'DELETED') + ':'}</span>
			<div className="text-muted text-start comment-content">
				<HtmlBlock
					className={classNames('break-words line-clamp-2', status.id !== 1 && 'text-sm text-muted italic')}
					content={cutContent}
				/>
			</div>
		</Stack>
	);
};

const CommentDeleted = ({ id, onHide, onRestore }) => {
	return (
		<Card className="my-2 border">
			<Card.Body className="flex flex-row justify-center items-center gap-1 p-4 leading-10">
				<span className="text-base">Комментарий удалён.</span>
				<Pressable onClick={() => onRestore(id)} className="link text-base mr-6">
					Отменить?
				</Pressable>
				<Button
					round
					size="rect"
					variant="danger-transparent"
					className="absolute right-4"
					onClick={() => onHide(id)}
				>
					<XMarkIcon className="text-red-500 w-5 h-5" />
				</Button>
			</Card.Body>
		</Card>
	);
};

const HeaderButtons = (props) => {
	const { id, author, isAuth, me, permissions, onDelete, onStash, setEdit, setReplyId } = props;

	const navigate = useNavigate();
	const showAuthModal = useAuthModal('comment');
	const showComplaintModal = useComplaintModal({ id, type: ComplaintTypes.COMMENT });

	return (
		<DropdownWidget
			options={[
				{
					value: 'Пожаловаться',
					onClick: showComplaintModal,
				},
				!me && {
					value: 'Личное сообщение',
					onClick: () => {
						if (!isAuth) return showAuthModal();

						sendMessagePrivate(author).then((data) => {
							if (data?.id) navigate(`/im/${data.id}`);
						});
					},
				},
				permissions?.can_comment_edit && {
					value: 'Редактировать',
					onClick: () => {
						setEdit(id);
						setReplyId(null);
					},
				},
				permissions?.can_comment_hide &&
					!me && {
						value: 'Скрыть',
						onClick: () => onStash(id),
					},
				permissions?.can_comment_delete && {
					value: 'Удалить',
					onClick: () => onDelete(id),
				},
			]}
		/>
	);
};

const CommentItem = (props) => {
	const {
		attachments,
		author,
		ck,
		content,
		datetime_create,
		deleted = false,
		edit,
		hide = false,
		id,
		isAuth,
		onHide,
		onPressVote,
		onRestore,
		params,
		parent,
		permissions,
		postId,
		query,
		rating,
		reply,
		status,
		voted,
	} = props;

	const [replyId, setReplyId] = reply;
	const [editId, setEdit] = edit;

	const ref = useRef();
	const navigate = useNavigate();

	useIntersection({
		ref: ref,
		type: 'comment',
		callback: () => handleEvent('comment_reach'),
		params: {
			rootMargin: '30px 0px',
			threshold: 0.5,
			trackVisibility: false,
			delay: 500,
		},
	});

	const handleEvent = (action, params = {}) => {
		Reach(action, { id: id, ck: ck, ...params });
	};

	const handleClickParent = (id) => {
		navigate(relative(document.location.href, `#c${id}`, { replace: true }));
	};

	const handleClickVote = (id, value) => {
		handleEvent('comment_vote', { ...{ value } });
		onPressVote(id, value);
	};

	if (!id || hide) return null;
	if (deleted) return <CommentDeleted {...{ id, onHide, onRestore }} />;

	let profile = author;
	if (!profile) {
		profile = {
			nick: 'DELETED',
			url: '',
			status: {
				id: 0,
			},
		};
	}
	if (!profile || isDeleted(author?.status?.id)) status.id = 0;

	return (
		<div
			ref={ref}
			className={classNames(
				'pt-4 first:pt-0 last:border-none',
				replyId !== id && editId !== id && 'pb-6 border-b border-dotted',
			)}
			id={`c${id}`}
		>
			<HStack className="justify-between items-start">
				<ProfileHeader isAuth={isAuth} {...profile}>
					<DatePretty value={datetime_create} />
					{params?.app_id && [2, 3].includes(params?.app_id) ? (
						<Link to="/install">
							<PhoneIcon className="w-3 h-3 ml-[2px] text-muted" title="Мобильное приложение" />
						</Link>
					) : null}
				</ProfileHeader>
				{status.id === 1 && <HeaderButtons {...props} {...{ setEdit, setReplyId }} />}
			</HStack>

			{parent ? <CommentParent {...parent} onClickParent={handleClickParent} /> : null}

			<div className="mt-2 mb-4 text-start block text-base img:w-[1rem] img:h-[1rem] comment-content">
				{editId === id ? (
					<CommentEditContainer
						value={emojify(content)}
						attachments={attachments}
						{...{ reply, postId, query, edit: [editId, setEdit], id }}
					/>
				) : (
					content && (
						<HtmlBlock
							className={classNames(
								'break-words overflow-hidden',
								status.id !== 1 && 'text-sm text-muted italic',
							)}
							content={content}
						/>
					)
				)}
			</div>

			{!!attachments?.length && attachments?.length > 0 ? <Attachments attachments={attachments} /> : null}

			{replyId === id ? (
				<CommentEditContainer {...{ reply, postId, query, edit }} />
			) : (
				editId !== id && (
					<HStack className="justify-between">
						<Button
							size="small"
							onClick={
								permissions.can_comment_reply && status.id === 1
									? () => {
											setReplyId(id);
											setEdit(null);
										}
									: undefined
							}
							disabled={!permissions?.can_comment_reply || status.id !== 1}
						>
							Ответить
						</Button>
						<CommentVote
							id={id}
							rating={rating}
							voted={voted}
							onPressVote={handleClickVote}
							disabled={!permissions?.can_comment_vote || status.id !== 1}
						/>
					</HStack>
				)
			)}
		</div>
	);
};

export default memo(
	CommentItem,
	(a, b) =>
		a.voted === b.voted &&
		a.rating === b.rating &&
		a.reply === b.reply &&
		a.deleted === b.deleted &&
		a.hide === b.hide,
);

const Attachments = ({ attachments }) => {
	const { size } = useWindowDimensions();
	const isMobile = size === 'sm';

	const listPhoto = attachments.filter((i) => i?.type === 'photo');
	const listVideo = attachments.filter((i) => i?.type === 'video');

	return (
		<Stack className="mb-3 md:w-[30rem]">
			{listPhoto?.length && listPhoto?.length > 0 ? (
				<AttachmentPhoto items={listPhoto} maxHeight={300} className="flex" />
			) : null}
			{listVideo?.length && listVideo?.length > 0 ? (
				<AttachmentVideo
					autoplay={false}
					items={listVideo}
					viewsCounter={false}
					size={isMobile ? '80vw' : '480px'}
				/>
			) : null}
		</Stack>
	);
};
